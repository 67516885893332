@import url('http://fonts.cdnfonts.com/css/sf-mono');
* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

@mixin flexCenter($direction) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: $direction;
}
@mixin gridCenter {
    display: grid;
    align-items: center;
    justify-content: center;
}
nav {
    position: fixed;
    top: 0;
    max-width: 100%;
    z-index: 999;
    padding-left: 30px;
    color: #64ffda;
    right: 30px;
    width: 100%;
}
ul {
    @include flexCenter(row);
    justify-content: flex-end;
    gap: 7rem;
    list-style: none;
    padding: 25px;
    background-color: transparent;
}
ul.active {
    background-color: var(--light-navy);
}

li {
    cursor: pointer;
    font-family: 'SF Mono', sans-serif;
                                                
}
.toggle-container {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 750px) {
    nav {
        position: absolute;
        top: 50px;
        right: -300px;
        width: 300px;
        z-index: 999;
        padding-left: 30px;
        color: #64ffda;
        background-color: var(--light-navy)
    }
    nav.active {
        right: 0;
    }
    ul {
        @include flexCenter(column);
        justify-content: flex-end;
        gap: 5rem;
        list-style: none;
        padding: 25px;
        background-color: transparent;
    }
}