div .hum-con {
    position: absolute;
    top: -23px;
    right: 330px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    gap: 5px;
    display: none;
}
.lines {
    width: 20px;
    height: 3px;
    margin-bottom: 10px;
    background-color: var(--green);
}
#1 {
    position: absolute;
    bottom: 0;
    right: 0;
    rotate: 45deg;
}


@media screen and (max-width: 750px) {
    div .hum-con {
        position: absolute;
        display: block;
    }
    
    
  }