:root {
  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2, 12, 27, 0.7);
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100, 255, 218, 0.1);
  --pink: #f57dff;
  --blue: #57cbff;
  --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
    sans-serif;
  --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-heading: 32px;
  --border-radius: 4px;
  --nav-height: 100px;
  --nav-scroll-height: 70px;
  --tab-height: 42px;
  --tab-width: 120px;
  --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --hamburger-width: 30px;
  --ham-before: top 0.1s ease-in 0.25s, opacity 0.1s ease-in;
  --ham-before-active: top 0.1s ease-out, opacity 0.1s ease-out 0.12s;
  --ham-after: bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ham-after-active: bottom 0.1s ease-out,
    transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
}
html {
	scrollbar-color: var(--dark-slate) var(--navy);
}
a {
  text-decoration: none;
  color: var(--green);
}
.logo svg {
  width: 60px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.App {
  text-align: left;
}
.App {
  background: #0a192f;
  background-size: auto;
  background-size: cover;
  color: #009C9C;
  min-height: 500px;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.side {
  position: fixed;
  bottom: 10px;
  left: 35px;
}
.sideright {
  position: fixed;
  bottom: 10px;
  right: 35px;
}
h2 {
  font-size: clamp(14px, 5vw, 16px);
  color: var(--lightest-slate);
}
h3 {
  margin-top: 10px;
  color: var(--slate);
  line-height: 0.9;
}
.big-heading {
	margin: 0px;
	font-size: clamp(40px, 8vw, 60px);
}
@media (max-width: 480px) {
  .big-heading {margin: 0 0 20px 2px;}
  
}
.hero-ptext {
  margin: 20px 0px 0px;
  max-width: 540px;
  font-size: 20px;
  color: var(--lightest-slate);
}
.cta {
	color: #64ffda;
	background-color: transparent;
	border: 1px solid #64ffda;
	border-radius: 4px;
	padding: 1.25rem 1.75rem;
	font-size: 14px;
	line-height: 1;
	text-decoration: none;
	cursor: pointer;
	margin-top: 50px;
}
.fullheight {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0px auto;
  padding: 100px 25px;
  max-width: 1000px;
}

.numbered-heading {
	display: flex;
	-moz-box-align: center;
	align-items: center;
	position: relative;
	margin: 10px 0px 40px;
	width: 100%;
	font-size: clamp(26px,5vw,var(--fz-heading));
	white-space: nowrap;
}
.projects-grid {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  position: relative;
  margin-top: 50px;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}





